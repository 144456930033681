// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "",
//   authDomain: "",
//   projectId: "",
//   storageBucket: "",
//   messagingSenderId: "",
//   appId: "",
//   measurementId: "",
// };

const firebaseConfig = {
  apiKey: "AIzaSyA9wFGutmSnmnONclNJeTvbWI4Lj7275Tc",
  authDomain: "gojo-shipper-auth.firebaseapp.com",
  projectId: "gojo-shipper-auth",
  storageBucket: "gojo-shipper-auth.firebasestorage.app",
  messagingSenderId: "976864196618",
  appId: "1:976864196618:web:a8525a54098e26c72947f3"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
